.Home-Container {
    /* height: fit-content; */
    /* min-height: 75px; */
    /* margin: 0; */
    /* display: flex; */
    /* justify-content: space-around; */
    /* justify-content: center;
    align-items: center; */
    /* flex-direction: column;
    flex-wrap: wrap; */
    /* text-align: center; */
    /* column-gap: 10px;
    row-gap: 10px; */

    /* background-color: #ffffff; */
    /* border: 1px solid var(--light-pink-color); */
    /* overflow: hidden; */
    overflow-x: hidden;
    /* padding: 2rem 12% 2rem 12%; */
    /* margin: 0 5rem 0 5rem; */
    /* margin-top: 2rem; */
    /* width: 80%; */
    /* row-gap: 2rem;
    column-gap: 2rem;
    justify-content: center;
    align-items: center; */
    position: relative;
    background: url("../../assets/images/home/africnOrnamints.jpg") center repeat;
}

.Home-Top-Div {
    height: 120vh;
}

.Home-Block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: center; */
    /* justify-content: start; */
}

.Home-Ticket-Image {
    min-width: 10rem !important;
    /* min-height: 15rem; */
    width: 15rem;
    aspect-ratio: auto;
}

.Bulletin {
    border-radius: 5px;
    border-color: blue;
    border-width: 5px;
    padding: 15px;
    background-color: var(--light-pink-color);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.Home-Fade-BG {
    width: 100%;
    height: 500px;
    background: url('../../assets/images/home/home_img_2Cropped_11zon.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    /* -webkit-animation: myfirst 5s; */
    /* Chrome, Safari, Opera */
    /* animation: myfirst 5s; */
    height: 600px;
}

.Home-Img-BG {
    width: 100%;
    height: auto;
    position: absolute;
    
   
}

.Home-Fade-BG1 {
    width: 100%;
    height: 500px;
    background: url('../../assets/images/home/home_img_1-min_11zon.jpg') no-repeat center center fixed;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    /* -webkit-animation: myfirst 5s; */
    /* Chrome, Safari, Opera */
    /* animation: myfirst 5s; */
    height: 600px;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes myfirst {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes myfirst {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}


.Home-Text-Div {
    font-size: 100px;
    font-family: cursive;
    opacity: 0.5;
}

.Home-Text-Div1 {
    /* background-color: rgb(246, 245, 245); */
    /* opacity: 0.5; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(20px + 30vw);
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 10px;
    z-index: 2;
    color: white;
    /* animation: div1Text 5s forwards; */
    opacity: 0.7;
    background-color: rgba(18, 18, 18, 0.425);
}

@media screen and (max-width: 1280px){
    .Home-Text-Div1 {
        background-color: rgba(18, 18, 18, 0.732);
        opacity: 0.9;
    }
}

@keyframes div1Text {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.7;
        background-color: rgba(18, 18, 18, 0.425);
    }
}

.Home-Image1 {
    width: 100%;
    height: auto;
}
.Footer-Container {
    /* width:100;
    min-height: 15rem;
    padding: 2rem; */
    /* background-image:repeating-linear-gradient(left ,var(--medium-1), var(--medium-2)); */
    /* min-height: 15rem; */
    background: linear-gradient(0deg, #ee6573 25%, #aa5757 75%);
    /* background: url("../../assets/images/footer/3233one1colored.png"); */
    /* background-size: auto 50px; */
    position: relative;
    /* margin-top: 20px; */
}

.Footer-Img {
    width: auto;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
}

.Footer-Background {
    border-radius: 5px;
    width: fit-content;
    padding: 2px;
    background-color: rgba(206, 237, 249, 0.675);
    color: rgb(18, 18, 13);
}
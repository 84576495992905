.Header {
  /* width:fit-content; */
  height: fit-content;
  /* min-height: 75px; */

  margin: 0;
  top: 0;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* z-index: 1; */

  display: flex;

  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  top: 0;
  left: 0;
  right: 0;





  column-gap: 10px;
  row-gap: 10px;
  z-index: 99;
  width: 100%;
  flex-wrap: wrap;
  padding: 5px;
  border-bottom: solid rgb(231, 228, 228) 1px;
}

.Header-Down {
  height: fit-content;
  min-height: 75px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid var(--light-pink-color);
  overflow: hidden;
  padding: 5px;

}


.Header-Icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 10px;
  column-gap: 15px;
  /* margin-top: 0px; */
}

.Header-Icon,
.Header-Icon>svg {
  /* margin-left: 10px;
  margin-right: 10px; */
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: var(--medium-orange-color);
}

.Header-Icon:hover>svg {
  color: var(--light-orange-color);
  transition: 0.5s;
}

.Header-Navigation-Icon,
.Header-Navigation-Icon>svg {
  height: 35px;
  width: 35px;
  color: var(--light-orange-color);
  border-radius: 5px;
}

.Top-Header-Navigation-Icon,
.Top-Header-Navigation-Icon>svg {
  height: 34px;
  width: 34px;
  color: var(--light-orange-color);
  border-radius: 5px;
  margin-top: 0.2rem;
}

.Small-Top-Header-Navigation-Icon,
.Small-Top-Header-Navigation-Icon>svg {
  height: 25px;
  width: 25px;
  color: var(--light-orange-color);
  border-radius: 5px;
}

.Selected>svg {
  color: rgb(0, 0, 0) !important;
}

/* .Header-Navigation-Icon:hover>svg {
  color: black;
  transition: 0.5s;
} */


.Header-Logo {
  /* height: 70px;
  width: 40px; */

}

.Header-Logo-Image {
  height: fit-content;
  width: 250px;
  margin-right: 10px;
}

.Header-Navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 100px;
  row-gap: 50px;
  flex-wrap: wrap;
}

.Top-Header-Navigation-Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  /* font-weight: bold; */
  width: fit-content;
  border-radius: 5px;
  padding: 7px;
}

.Header-Navigation-Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  /* font-weight: bold; */
  width: 50px;
  border-radius: 5px;
  padding: 7px;
}

.Header-Navigation-Item:hover,
.Top-Header-Navigation-Item:hover {
  background-color: var(--light-pink-color);
}

.Header-Navigation-Item .Selected,
.Top-Header-Navigation-Item .Selected {
  background-color: var(--medium-light-pink-color);
  font-weight: bold;
}



.Language-Icons-Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}

.Language-Selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 2px;
  column-gap: 2px;
}

.Selected-Language {
  background-color: var(--light-2);
  border-radius: 5px;
  /* padding: 3px; */
}

.Language-Option {
  padding: 3px;
}

.Separator>svg {
  width: 1.5rem;
  height: 1.5rem;
}

.Resources-Item-Style {
  padding: 0.3rem 0.5rem;
  margin: 0.15rem 0;
  font-size: 14px;
}

.Header-Item-Style {
  padding: 0.3rem 0.5rem;
  margin: 1rem 0;
  font-size: 20px;
  width: 10rem;
}


.Header_Button {
  text-decoration: none;
}

.Selected-Header-Button {
  border-bottom: #dc2f2f solid 3px;
}


.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}





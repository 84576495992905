
.Get-Involved-Card-Container {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    column-gap: 50px;
    row-gap: 50px;
    padding: 50px;
}

.Get-Involved-Card-Wrap{
    padding: 20px;
    background-color: rgba(245, 245, 245, 0.474);
    height: fit-content;
    width: calc(50px + 40vw);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Get-Invovled-Card-Img {
    width: 200px;
    height: auto;
    border-radius: 10px;
}

.Get-Involved-Card-Text {
    width: 250px;
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    row-gap: 10px;
}

.Get-Involved-Sub-Text {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    row-gap: 10px;
}
.Contact-Container {
/* 
    display: flex;

    flex-direction: column;
    flex-wrap: wrap;
 

    background-color: #ffffff; */

    overflow-x: hidden;

    /* margin-top: 2rem;

    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: center;
    align-items: center; */
        background: url("../../assets/images/about us/leftOpacity20.png") left repeat-y, url("../../assets/images/about us/rightOpacity20.png") right repeat-y;

    padding-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .Contact-Container {
        background: url("../../assets/images/about us/leftOpacity20.png") left repeat-y;
    }
}


.ContactUs-Form {
    margin-top: 3rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    row-gap: 2rem;
    column-gap: 2rem;


    /* margin-top: 15px;
    padding: 30px; */
    /* border: 1px solid black; */
    /* background-color: cadetblue; */
    /* width: 50%; */
}
.Contact-Form-Input {
    /* background-color: brown; */
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-around; */
    justify-content: center;
    /* width: 300px; */
    flex-wrap: wrap;

    row-gap: 0.5rem;
    column-gap: 0.5rem;

}

.Contact-Input-Width {
    width: 70%;
}

.Contact-Input-Field {
    /* width: 230px; */

    /* background-color: #fff; */
    display: flex;
    flex-direction: column;
    /* flex-direction: column; */
    /* justify-content: center;
  align-items: center; */
    border: 2px solid #d9d0c7;
    border-radius: 5px;
    background: transparent;
    /* margin: 5px;
    padding: 5px; */
    font-size: 18px;
    color: rgb(0, 0, 0);

    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
}

.Contact-Input-Field:focus {
    border: 2px solid var(--medium-3);
}


::-webkit-input-placeholder {
    color: darkgray;
    font-size: 18px;
    /* padding: 2px; */
}


.Contact-Submit {
    width: 15rem;
    height: 3rem;
    /* background-color: #fff; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    font-size: 20px;
    font-weight: bold;


    -webkit-transition: 0.5s;
    transition: 0.5s;
}


.validated-button-submit {
    color: black;
    border: 1px solid var(--medium-3);
    cursor: pointer;
}

.invalidated-button-submit {
    color: #495F73;
    border: 1px solid #495F73;
    opacity: 70%;
}

.validated-button-submit:hover {
    background-color: lightcoral;
    color: var(--dark-3);

    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.Home-Block-Image-0-1 {
    min-width: 15rem;
    /* min-height: 15rem; */
    width: fit-content;
    /* aspect-ratio: 3/2; */
}

.Contact-Name-Card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 300px;
}

.Contact-Card-Img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
}
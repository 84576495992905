.About-Container-Wrap {
    background: url("../../assets/images/about us/leftOpacity20.png") left repeat-y, url("../../assets/images/about us/rightOpacity20.png") right repeat-y;
}

.About-Container {
    /* height: fit-content; */
    /* min-height: 75px; */
    /* margin: 0; */
    display: flex;

    justify-content: center;

    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    /* text-align: center; */
    /* column-gap: 10px;
    row-gap: 10px; */


    /* border: 1px solid var(--light-pink-color); */
    /* overflow: hidden; */
    overflow-x: hidden;
    /* padding: 2rem 12% 2rem 12%; */
    /* margin: 0 5rem 0 5rem; */
    /* margin-top: 2rem; */
    /* width: 80%; */
    row-gap: 2rem;
    column-gap: 2rem;
    /* align-items: center; */
}

@media only screen and (max-width: 767px) {
    .About-Container-Wrap {
            background: url("../../assets/images/about us/leftOpacity20.png") left repeat-y;
    }
}


.About-Image-Logo {
    min-width: 10rem;
    /* min-height: 15rem; */
    max-width: 18rem;
    /* aspect-ratio: 3/2; */
    margin-right: 6rem;
}

.About-Text {
    min-width: 300px;
    max-width: 600px;
    text-align: center;
}

.About-Text-1 {
    min-width: 300px;
    max-width: 600px;
}

.About-Image {
    height: 300px;
    /* max-width: 300px; */
}
.About-Image1 {
    min-width: 10rem;
    /* min-height: 15rem; */
    max-width: 23rem;
    aspect-ratio: 2/2;
}

.About-Img-Wrap {
    background: url("../../assets/images/about us/pngTextWrap.png") no-repeat ;
    background-size: 100px 120px;
    background-position: 50% 0%;
    height: 120px;
    margin-top: 0px;
    padding-top: 48px;
}

.About-Img-Wrap-Medium {
    background: url("../../assets/images/about us/pngTextWrap.png") no-repeat;
    background-size: 100px 120px;
    background-position: 50% 0%;
    height: 120px;
    margin-top: 0px;
    padding-top: 42px;
    font-size: 21px;
}

.About-Img-Wrap-Big {
    background: url("../../assets/images/about us/pngTextWrap.png") no-repeat;
    background-size: 100px 120px;
    background-position: 50% 0%;
    height: 120px;
    margin-top: 0px;
    padding-top: 38px;
    font-size: 25px;
}

.About-Div-Holder {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    row-gap: 30px;
}

.About-Div-Sub-Holder {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    row-gap: 10px;
}

.About-Arrow > svg {
    color: black;
    width: 30px;
    height: 30px;
}
.Map_Container {
    /* margin-top: 2rem; */
}

.Map {
    width: 100%;
    height: calc(100vh - 185px);
}

/* .mapboxgl-canvas-container {

    height: 100vh;

} */

.Sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 100px;
    margin-top: 80px;
    border-radius: 4px;
}

.marker, .marker > svg {
    width: 35px;
    height: 35px;
    color: var(--dark-pink-color);
}

.green {
    color: green !important;
}

.Search_Result_Container {
    position: absolute;
    top:0;
    left: 0;
    width: fit-content;
    height: fit-content;
    z-index: 2;
}
.Styles_Container {
    position: absolute;
    bottom:0;
    right: 0;
    width: fit-content;
    height: fit-content;
    z-index: 99;
}

.Filters_Container {
    position: absolute;
    top:0;
    /* left: 25%; */
    right:0;
    /* width: fit-content; */
    min-width: 8rem;
    max-width: 30rem;
    z-index: 2;
}
.Filters_Container-Left {
    position: absolute;
    top: 0;
    /* left: 25%; */
    left: 0;
    /* width: fit-content; */
    min-width: 8rem;
    max-width: 30rem;
    z-index: 2;
}

.Filters_Container-Left-Middle {
    position: absolute;
    top: 0px;
    /* left: 25%; */
    left: 150px;
    /* width: fit-content; */
    min-width: 8rem;
    max-width: 30rem;
    z-index: 2;
}

.Selected_Pin_Container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    z-index: 999;
    /* transform: translateY(2%); */
}

.Pin {
    /* width: 22rem; */
    border-radius: 5px;
    /* background-color: #E6DFE9; */
    padding: 3px;
    font-size: 11px;
    width: 20rem;
    display: flex;
    flex-direction: column;
    column-gap: 3px;
    row-gap: 3px;
}


.Admin-Pin {
    /* width: 22rem; */
    border-radius: 5px;
    /* background-color: #E6DFE9; */
    padding: 3px;
    font-size: 11px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    column-gap: 3px;
    row-gap: 3px;
    background-color: thistle;
    z-index: 2;

}

.mapboxgl-popup-close-button {
    width: 1.4rem !important;
    height: 1.3rem !important;
    color: var(--medium-2);
    font-size: 26px;
}
.mapboxgl-popup-close-button:hover {
    color: black !important;
    background-color: var(--light-2) !important;
}
.mapboxgl-popup-content {
    width: fit-content !important;
}

.Change_Button {
    background-color: var(--light-orange-color);
    width: fit-content;
    padding: 2px;
    border-radius: 5px;
    text-decoration: none;
    color: black;
}

.form-select {
    /* width: fit-content !important; */
    background-color: var(--light-1) !important;
        /* background: linear-gradient(var(--light-2), var(--medium-2)) !important; */
        box-shadow: none !important;
}

.Styles_Container_Option {
    background: linear-gradient(var(--light-1), var(--medium-1)) !important;
    box-shadow: none !important;
}

.Styles_Container_Option :hover {
    background: linear-gradient(var(--light-1), var(--medium-1)) !important;
    box-shadow: none !important;
}

.Read_More-Button:hover {
    color: var(--medium-1);
}

.Read_More-Button {
    cursor: pointer;
    color: #16169c;
}

.Map_Pin_Popup_Separator{
    border-bottom: 1px var(--light-3) solid;
    margin-top: 3px;
    margin-bottom: 3px;
}